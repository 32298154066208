<template>
  <svg
    width="18"
    height="16"
    viewBox="0 0 18 16"
    stroke-width="2"
    stroke="currentColor"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M1 8H17M10 1L17 8L10 15"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
</template>
